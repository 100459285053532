import React from 'react';
import classnames from "classnames";
import { Link } from 'react-router-dom';
import { isClient } from '../../../../utils'

import './dropdown.css';

const isActive = (link) => {
  return link === isClient && window.location.pathname;
};

const DropdownItem = ({ name, link, isActive, ...rest }) => {
  return (
    <li className={classnames('dropdown-list-item',
      {'dropdown-list-item__active': isActive}
    )}>
      <Link to={link} className='dropdown-list-item-link'>{ name }</Link>
    </li>
  );
}

export default({ items, ...rest }) => {

  const isItemsIsset = items && items.length;

  const activeItem = items.find(item => isActive(item.link));

  const activeItemName = (activeItem && activeItem.name) || items[0].name;

  if (!isItemsIsset) {
    return null;
  }

  return (
    <div className='dropdown'>
      <div className='dropdown-title'>{ activeItemName }</div>
      <ul className='dropdown-list'>
        {items.map((item, index) => {
          return (
            <DropdownItem
              {...item}
              isActive={activeItemName === item.name}
              key={`DropdownItem-${index}`}
            />
          );
        })}
      </ul>
    </div>
  )
}
