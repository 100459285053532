import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Hero16 } from '../../../../themes/nerdify/components/Hero';
import {Header1, HeaderIcon, HeaderIconCALight} from './../../../../themes/nerdify/components/Header';

import { HIWItems2 } from '../../blocks/Hiw';
import { Hiw2, HiwImg, HiwItem, HiwList, HiwText } from '../../../../themes/nerdify/components/Hiw';
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import Holder from './../../../../themes/nerdify/components/Holder'
import { Section11, SectionBlock } from '../../../../themes/nerdify/components/Section';
import { Footer2 } from '../../blocks/Footer'
import {TextBack4a} from '../../blocks/TextBack'
import { HnavMob1 } from '../../blocks/HnavMob';
import { Hnav1 } from "../../blocks/Hnav";
//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';

import LocalesPage from './../LocalesPage';
import {TopNerdsInfo2, TopNerdsInfo3} from "../../../../themes/nerdify/components/TopNerdsInfo";
import DemoSubjects from "../../../../themes/nerdify/components/DemoSubjects";
import {Intro4} from "../../../../themes/nerdify/components/Intro";
import DemoCards from "../../blocks/DemoCards";
import SliderCombined from "../../../../themes/nerdify/components/SliderCombined";
import Text from "../../../../base_components/Text";
import {SliderCombinedItems} from "../../blocks/SliderCombined";
import {useMobile} from "../../../../hooks";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class Landing extends Component {
  render () {
    return (
      <WithHook>
        {({
            isMobile
          }) => {
    return <LocalesPage {...this.props}>
      <Header1>
        <Holder>
          <NavLink className="logo-link" to="/">
            <HeaderIconCALight />
          </NavLink>
          {isMobile ? <HnavMob1 /> : <Hnav1 theme='light'/>}
{/*
          <div className="header-wrap">
            <NavLink className='button button__type9 hero__go-home' to='/'>Go to Home page</NavLink>
            <HnavMob1 />
            <InputSelectLocales
              className='input-select__short'
              name='country'
              isError={false}
            />
          </div>
*/}
        </Holder>
      </Header1>

      <Hero16>
        <Holder className="hero__holder">
          <h1 className='h0 hero__title'>Let's see how it works!</h1>
          <h5 className='h5 hero__subtitle'>Really easy: just text us! The next thing you know, our automated algorithm will pick the best matching freelance Nerd for your request.</h5>
        </Holder>
      </Hero16>

      <Hiw2 indent={"pt60 pb60"}>
        <Holder className={"holder_3"}>
          <HiwList>
            {HIWItems2.map((item, index) => {
              return (
                <HiwItem key={`hiwItem${index}`}>
                  <HiwImg img={item.img} />
                  <HiwText>
                    <h5 className='h5'>{item.step}</h5>
                    <div className='p'>{item.message}</div>
                  </HiwText>
                </HiwItem>
              )
            })}
          </HiwList>
        </Holder>
      </Hiw2>

{/*
      <Holder className={"holder_3"}>
        <hr className="hr hr_1"/>
      </Holder>
*/}

      <Section11>
        <TopNerdsInfo2 />

        <DemoSubjects
          title={
            <Text>
              Our Nerds are graduates in <br className="mobile-only" />
              <span className="strong">75+ different fields</span>…
            </Text>
          }
        />

        <Nerds2 indent={'pt50 pb40'}>
          <div className="nerds__title">
            …from the{' '}
            <span className="strong">TOP universities</span> worldwide
          </div>
        </Nerds2>
      </Section11>

      <Intro4 indent={"pb60m pb60d"}>
        <Holder className={"holder_3"}>
          <div className="intro__title">Disclaimer</div>
          <div className="intro__msg">Please be aware that freelance Nerds agreed to follow our Honor Code that does not allow providing any type of service that violates academic, corporate or any other policies. Please read our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink> and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks to learn more.</div>
        </Holder>
      </Intro4>

      <DemoCards titleTag={"h2"} />

      <SliderCombined theme={1} indent={"pb40"}
                      title={<Text>Uncertain where to start?<br className="desktop-only"/> Check out the most popular requests!</Text>}
                      items={ SliderCombinedItems }
      />

      <SectionBlock theme={3} indent={"pt40m pt40d pb40m pb40d"}>
        <Holder className="holder_3">
          <div className='h1'>Having doubts whether you need a Nerd?</div>
          <p className='h5'>Send us a message to learn more about how we can help you today!</p>
          {/*<TextBack2a />*/}
          <TextBack4a subTheme={3} />
        </Holder>
      </SectionBlock>

      <Footer2 />
    </LocalesPage>
        }}
      </WithHook>
    )
  }
}
