import React from 'react';
import {NavLink} from 'react-router-dom';
import { useMobile } from '../../../../hooks';
import Holder from './../../../../themes/nerdify/components/Holder';
import { HeaderCA, HeaderIconCA } from './../../../../themes/nerdify/components/Header';
import { Footer2 } from '../../blocks/Footer'
import Content from './../../../../themes/nerdify/components/Content';
import TextPage from './../../../../themes/nerdify/components/TextPage';
//import { HnavMob2 } from '../../blocks/HnavMob';
//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import {HnavMobExt} from "../../../../themes/nerdify/components/HnavMob";
import {HeaderNavListExt, HeaderNavSubList} from "../../blocks/HeaderNav";
import {HnavExt} from "../../../../themes/nerdify/components/Hnav";

export default ({ className, children, ...rest }) => {
  const isMobile = useMobile();
  return (
    <TextPage>
{/*
      <Header2 key='textpage-header'>
        <Holder>
          <NavLink className='logo-link' to='/'>
            {rest.au ?
              <HeaderIcon iconName='logo-au' />
              :
              <HeaderIcon iconName='logo-us' />
            }
          </NavLink>
          <div className="header-wrap">
            <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
            <HnavMob2 />
            <InputSelectLocales
              className='input-select__short'
              name='country'
              isError={false}
            />
          </div>
        </Holder>
      </Header2>
*/}
      <HeaderCA type={1}>
        <Holder>
          <NavLink className="logo-link" to="/">
            <HeaderIconCA />
          </NavLink>
          <div className="header-wrap">
            {isMobile ? <HnavMobExt list={HeaderNavListExt} sublist={HeaderNavSubList} /> : <HnavExt list={HeaderNavListExt} /> }
          </div>
        </Holder>
      </HeaderCA>
      <Content className={className} key='textpage-content'>
        {!rest.noWrap ? <div className="textPage">{children}</div> : children}
      </Content>
      <Footer2 key='textpage-footer' />
    </TextPage>
  )
}

