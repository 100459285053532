import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import NotFound from './../../../../themes/nerdify/components/NotFound';
import TextPage from './../TextPage';

export default () => (
  <TextPage className="reportAbuse" noWrap au>
    <NotFound>
      <Holder className={'holder_1 not-found'}>
        <h1 className="h2">Oops..</h1>
        <h6 className="h6">Hang in there, we're down for scheduled maintenance right now. Be back soon!</h6>
      </Holder>
    </NotFound>
  </TextPage>
)
