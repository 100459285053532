import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';
//import { useSelector } from 'react-redux'
import { useMobile } from '../../../../hooks';

import LocalesPage from '../LocalesPage';
import BottomModal from '../../../../components/BottomModal';

import { HeaderNavListExt, HeaderNavSubList } from '../../blocks/HeaderNav';
import {
  HeaderCA,
  HeaderIconCA,
} from '../../../../themes/nerdify/components/Header';
import { HnavExt } from '../../../../themes/nerdify/components/Hnav'
import { HnavMobExt } from '../../../../themes/nerdify/components/HnavMob';

import { HeroNew2 } from '../../../../themes/nerdify/components/Hero';

import SaveTime, {SaveTimeIconImg} from '../../../../themes/nerdify/components/SaveTime';
import OurPrices from '../../../../themes/nerdify/components/OurPrices';
import PayList from '../../../../themes/nerdify/components/PayList';
import Text from '../../../../base_components/Text';

import { HiwItems } from '../../blocks/Hiw';
import {
  Hiw7,
  HiwList2,
} from '../../../../themes/nerdify/components/Hiw';

import { slider3Items } from '../../blocks/Slider1';
import SlickSlider1 from '../../../../themes/nerdify/components/SlickSlider1';

import { SliderCombinedItems } from '../../blocks/SliderCombined';
import SliderCombined from '../../../../themes/nerdify/components/SliderCombined';

import DemoCards from '../../blocks/DemoCards';
import DemoChat from '../../../../themes/nerdify/components/DemoChat';
import {
  SectionBlock,
  Section11,
} from '../../../../themes/nerdify/components/Section';
import { Footer2 } from '../../blocks/Footer';
import Press, {
  PressList,
  PressItem,
  PressIcon,
} from '../../../../themes/nerdify/components/Press';

import Icon from '../../../../themes/nerdify/components/Icons';
import {TextBack3, TextBack7} from '../../blocks/TextBack';
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import { TopNerdsInfo2 } from '../../../../themes/nerdify/components/TopNerdsInfo';
import DemoSubjects from '../../../../themes/nerdify/components/DemoSubjects';

import { FAQItems } from "../../blocks/FAQ";
import {
  Faq4,
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon,
} from '../../../../themes/nerdify/components/Faq';

import {Intro4} from "../../../../themes/nerdify/components/Intro";
//import {InputSelectLocales} from "../../../../themes/nerdify/components/Form/InputSelect";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile,
  });
};

class LandingAcademia extends Component {
  constructor(props) {
    super(props);
    this.openHiwModal = this.openHiwModal.bind(this);
    this.closeHiwModal = this.closeHiwModal.bind(this);
  }

  openHiwModal() {
    this.howItWorksModalRef.show();
  }

  closeHiwModal() {
    this.howItWorksModalRef.hide();
  }

  render() {
    //const { pageCfg } = this.props;

    return (
      <WithHook>
        {({ isMobile }) => {
          //const locale = useSelector(state => state.locales.locale);

          return (
            <LocalesPage {...this.props}>
              <div className="landing">
                <HeaderCA type={3}>
                  <Holder>
                    <NavLink className="logo-link" to="/">
                      <HeaderIconCA />
                    </NavLink>
                    <div className="header-wrap">
                      {isMobile ? <HnavMobExt list={HeaderNavListExt} sublist={HeaderNavSubList} /> : <HnavExt list={HeaderNavListExt} /> }
                    </div>
                  </Holder>
                </HeaderCA>

                <HeroNew2 subTheme={4}>
                  <div className="hero-main">
                    <div className="holder">
                      <h1 className="hero__title">Looking for on-demand<br className="desktop-only" /> research assistance?</h1>
                      <h5 className="hero__subtitle">Type your phone number and we’ll<br className="desktop-only" /> text you back in 10 sec to help ASAP</h5>
                      <TextBack7 {...this.props} subTheme={1} submitBtnTxt={'Text me now'} />
                    </div>
                  </div>
                  <div className="hero-rate">
                    <div className="holder">
                      <div className="hero-rate-msg">
                        76,750 users rated Nerds' assistance 4.7/5
                      </div>
                      <div className="hero-rate-icon" />
                    </div>
                  </div>
                </HeroNew2>

                <SectionBlock theme={1} indent={"pt40m pt30d pb40m pb60d"}>
                  <Holder className="holder_3">
                    <div className="section_title">Nerdify platform is created to give you instant access to freelance Nerds who can assist with research, data collection, data analysis, and other requests!</div>
                  </Holder>
                </SectionBlock>

                <Hiw7 subTheme={1}>
                  <div className="holder hiw-holder1">
                    <h3 className="h3">Get your own on-demand personal research assistant!</h3>

                    {!isMobile &&
                      <HiwList2 items={HiwItems} />
                    }
                    {!!isMobile &&
                      <div className="hiw-subtitle">
                        Just text us or send a photo of your task & get the best
                        Nerd assigned
                      </div>
                    }
                    <div className="hiw-demochat">
                      <DemoChat
                        items={[
                          { text: <Text>I need to create a survey for<br/> my research, can you help?</Text>, type: 'user' },
                          {
                            text:
                              'Sure! Just send the details📷',
                            type: 'nerd',
                          },
                          { type: 'img', text: 'case4.jpg' },
                          { text: 'I really need it by morning!', type: 'user' },
                          {
                            text:
                              'No worries, one of our TOP Nerds can start working on it asap!',
                            type: 'nerd',
                          },
                        ]}
                      />
                    </div>

                    {!!isMobile && <>
                      <button
                        className="button__type14"
                        type="button"
                        onClick={this.openHiwModal}
                      >
                        <Icon className={`svg-icon`} iconName="small-arrow"/>
                        <div>Learn more details</div>
                      </button>

                      <BottomModal
                        ref={(ref) => {
                          this.howItWorksModalRef = ref;
                        }}
                      >
                        <div className="hiw-bottom-modal">
                          <button
                            className="hiw-modal-close"
                            onClick={this.closeHiwModal}
                          >
                            <Icon className="svg-icon" iconName="close-icon3" />
                          </button>

                          <h3 className="h3">Here is how it works</h3>
                          <HiwList2 items={HiwItems} />
                        </div>
                      </BottomModal>
                    </> }

                  </div>

                  <div className="hr_4" />

                  <div className="holder hiw-holder2">
                    <p className="p__tiny hiw-honor-line">Freelance Nerds can help you with anything to make your study or work life easier! However, they will not provide services that violate our <Link className="link" to="/honor-code">Honor Code</Link>, academic, corporate or any other policies. Please read <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</p>
                  </div>
                </Hiw7>

                <Press className={'press__2 press__2_1'}>
                  <div className="press-wrapper">
                    <div className="p p__title press__title">Featured on</div>

                    <PressList>
                      <PressItem>
                        <PressIcon iconName={'forbes'} />
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://www.wired.com/story/xai-meeting-ai-chatbot/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'wired'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://tech.co/startups-redefining-educational-space-2017-04"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'tech-co'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://www.nextmedia-hamburg.de/wp-content/uploads/2018/12/nextMedia.Kompass_Trendreport_Oktober_2016.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'next-media'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'venturebeat'} />
                        </a>
                      </PressItem>
                    </PressList>
                  </div>
                </Press>

                <SaveTime className="save-time-block">
                  <div className="holder">
                    <div className="h3 save-time-title">Let Nerdify Save Your Time and Effort</div>
                    <div className="save-time-wrapper">
                      <ul className="save-time-list">
                        <li className="list-title">Spend less time...</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No lengthy forms to fill out</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No need to search for the best freelancer</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No exhausting quote negotiations</div>
                        </li>
                      </ul>

                      <ul className="save-time-list">
                        <li className="list-title">…get better results</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Get an immediate reply to your request</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Describe a task or send a pic/video</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Get free updates via SMS/Messenger</div>
                        </li>
                      </ul>

                    </div>
                  </div>
                </SaveTime>


                <Section11>
                  <TopNerdsInfo2 />

                  <DemoSubjects
                    title={
                      <Text>
                        Our Nerds are graduates in <br className="mobile-only" />
                        <span className="strong">75+ different fields</span>…
                      </Text>
                    }
                  />

                  <Nerds2 indent={'pt50 pb40'}>
                    <div className="nerds__title">
                      …from the{' '}
                      <span className="strong">TOP universities</span> worldwide
                    </div>
                  </Nerds2>
                </Section11>

                <SlickSlider1
                  title="Nerds complete over 200 requests for academics per week!"
                  items={slider3Items}
                />

                <Intro4 indent={"pt40m pt60d pb40m pb30d"}>
                  <Holder className={"holder_3"}>
                    <div className="intro__title">Disclaimer</div>
                    <div className="intro__msg">Please be aware that freelance Nerds agreed to follow our Honor Code that does not allow providing any type of service that violates academic, corporate or any other policies. Please read our  <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink> and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks to learn more.</div>
                  </Holder>
                </Intro4>

                <div className="hr_6" />

                <SliderCombined theme={1} indent={"pt40m pt30d pb40"}
                                activeItem={'Academia'}
                                title={<Text>Uncertain where to start?<br className="desktop-only"/> Check out the most popular requests!</Text>}
                                items={ SliderCombinedItems }
                />

                <SectionBlock theme={2} indent="pb80m pt70d pb70d">
                  <Holder className="holder_3">
                    <div className="section-title pb30">
                      Send your personal request <br className="desktop-only" /> &
                      we'll text you in 10 sec to help ASAP
                    </div>
                    <TextBack3 submitBtnTxt={'Text me now'} />
                    {/*<TextBack4 submitBtnTxt={'Text me now'} subTheme={3} />*/}
                  </Holder>
                </SectionBlock>


                <DemoCards titleTag={"h2"} />

                <OurPrices
                  msg1="We charge only for the time your Nerd spends working on your request: our platform fee (11%) is already included! Describe your task and we'll calculate the price. If this is your first time using Nerdify, you can get the welcome discount. Ask for more details in chat!"
                  msg2="Your satisfaction is our #1 priority!"
                />

                <PayList />

                <Faq4>
                  <Holder>
                    <h3 className='h3'>FAQ</h3>
                    <Accordion>
                      {FAQItems.map((item, index) => {
                        return (
                          <AccordionItem index={index} key={`faqItem${index}`}>
                            <AccordionHead>
                              <AccordionIcon icons={['plus', 'minus']} />
                              <h6 className='h6'>{item.Q}</h6>
                            </AccordionHead>
                            <AccordionBody>
                              <p className='p'>{item.A}</p>
                            </AccordionBody>
                          </AccordionItem>
                        )
                      })}
                    </Accordion>
                  </Holder>
                </Faq4>

                <Footer2 />
              </div>
            </LocalesPage>
          );
        }}
      </WithHook>
    );
  }
}

export default LandingAcademia;
