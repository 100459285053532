import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import './index.css';

export default (props) => {
  const prohibitedTasksAnchorRef = useRef(null);
  useEffect(() => {
    if (
      prohibitedTasksAnchorRef.current &&
      props.location.hash === '#prohibited-tasks'
    ) {
      prohibitedTasksAnchorRef.current.scrollIntoView(true);
    }
  }, []);
  return (
    <LocalesPage {...props}>
      <TextPage au>
        <Holder className="holder_1 honor-page">
          <h1 className="h1 h1_1">The Honor Code</h1>

          <Link
            to="/report-abuse"
            className="button button__type8 button__top"
            type="button"
          >
            report abuse
          </Link>

          <h6 className="h6">
            The Honor Code is a policy promoting Nerdify Platform usage
            integrity. It articulates our expectations of students, businesses,
            academia, and freelancers in establishing and maintaining the
            highest standards in academic and corporate work.
          </h6>

          <div className="textPage-item">
            <h4 className="h4">Nerdify Honor Code</h4>
            <p className="p">
              Nerdify Platform does not condone and will not be involved in
              facilitating corporate or academic dishonesty, such as knowingly
              helping students in cheating or obtaining grades/degrees they
              did not earn; helping businesses in committing fraud; helping
              academia in falsifying research results.</p>
            <p className="p">
              <strong>This includes:</strong>
            </p>
            <ul className="ul">
              <li>
                Cheating: Requesting or providing unauthorized outside help
                on an academic assignment;
              </li>
              <li>
                Impersonation: Assuming a student’s identity for any
                purpose;
              </li>
              <li>
                Plagiarism: Using the work of another person without proper
                attribution.
              </li>
              <li>Illegal action: Creating fake reports of any kind;</li>
              <li>
                Academic misconduct: Assisting in forgery of academic
                degrees, fabrication or alteration of data.
              </li>
            </ul>
            <p className="p">
              Freelancers shall not take tests nor complete any graded
              assignments for students. Freelancers shall not falsify data
              or fabricate research results and reports for any purpose.
              Freelancers may not knowingly assist a student, business owner
              or academia cheat, fake reports, plagiarize, or engage in any
              activity that violates corporate or academic policies. Any
              evidence that a freelancer has knowingly facilitated fraud
              will be the reason for their immediate ban on the platform.
            </p>
            <p className="p">
              Nerdify Platform does not condone and will not be involved in
              facilitating any corporate dishonesty or corporate policies
              violation, such as knowingly assisting Users to commit fraud or
              contribute to any kind of illegal activity.</p>
            <p className="p">
              Any evidence that a freelancer has knowingly facilitated any
              kind of illegal activity will be the reason for their
              immediate ban on the platform.
            </p>
          </div>

          <div className="textPage-item">
            <h4 className="h4">To Students</h4>
            <p className="p">
              Nerdify team understands the pressures and time constraints
              studying creates. However, the risk students take by violating
              their institutional policies of academic integrity is not worth
              the reward. Copying solutions or requesting final answers promotes
              completion without comprehension, which is not supported on
              Nerdify Platform.
            </p>
            <ul className="ul">
              <li>
                Students are not allowed to use Nerdify Platform for any kind of
                illegal activity or violations of any academic policies.
              </li>
              <li>
                Students are not allowed to use any educational materials
                received from Freelancers as their own.
              </li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">To Businesses</h4>
            <p className="p">
              At Nerdify we give you an opportunity to get professional help
              with your tasks from experts in their fields. However, you should
              remember that violating any corporate policy is prohibited on
              Nerdify Platform.
            </p>
            <ul className="ul">
              <li>
                Business representatives are not allowed to use Nerdify Platform
                for any kind of illegal activity.
              </li>
              <li>
                Business representatives are not allowed to use freelancers to
                contribute to any kind of fraud activity.
              </li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">To Academia</h4>
            <p className="p">
              Here at Nerdify we applaud those who have chosen to devote their
              lives to research and make contributions into the future of
              science. We are determined to assist in every possible way by
              giving access to high-quality help from Freelancers on our
              platform. However, we stand against data falsification and
              deliberate misappropriation of the research work.
            </p>
            <ul className="ul">
              <li>
                Academics are not allowed to use Nerdify Platform for any kind
                of making up data or results, changing or misreporting data or
                results, plagiarism;
              </li>
              <li>
                Academics are not allowed to ask Freelancers to contribute to
                publication of fabricated scientific research articles.
              </li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">To Freelancers</h4>
            <p className="p">
              At Nerdify Platform, we give you great opportunity and place trust
              in you representing yourself as a professional to students,
              businesses, and academia using our platform. As a Freelancer
              working on Nerdify Platform you're required to follow these
              guidelines:
            </p>
            <ul className="ul">
              <li>
                Freelancers are not allowed to create or contribute to
                circumstances that would violate any corporate or academic
                policies.
              </li>
              <li>
                Freelancers are not allowed to help create the aforementioned
                circumstances.
              </li>
              <li>
                Freelancers are not allowed to help students, business owners or
                academics to cheat or commit fraud.
              </li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">Contact Information Sharing</h4>
            <p className="p">
              Users of Nerdify Platform are not allowed to ask other Users to
              share their personal contacts. You should utilize the Nerdify
              Platform to communicate with other Users.
            </p>
          </div>

          <div className="textPage-item">
            <h4
              className="h4"
              id="prohibited-tasks"
              ref={prohibitedTasksAnchorRef}
            >
              List Of Prohibited Task Requests
            </h4>
            <p className="p">
              Any User of Nerdify platform that infringes the restrictions
              specified in our Honor Code will be banned and their account will
              be deactivated permanently with no option for recovery.
            </p>
            <p className="p">
              Here is the list of requests that violate rules of Nerdify platform (please be aware that the list is not exhaustive and should be used as a guide):
            </p>
            <ul className="ul">
              <li>Making fake financial reports;</li>
              <li>Creating CVs with fake job experience;</li>
              <li>Completing college/school homework tasks;</li>
              <li>Impersonating a student’s identity for any purpose;</li>
              <li>
                Preparing educational materials to present them by student as
                their own work;
              </li>
              <li>
                Getting answers for tests, exams, labs when instructed by
                professor or institution not to use outside help;
              </li>
              <li>Forging documents of any type;</li>
              <li>Ghostwriting of dissertations, theses and term papers;</li>
              <li>Fabricating data, information, or citations;</li>
              <li>
                and other tasks that violate any of college or corporate
                policies.
              </li>
            </ul>
          </div>

          <div className="textPage-item">
            <h4 className="h4">Honor Code Abuse</h4>
            <p className="p">
              If you become aware of any case of Nerdify Platform Honor Code
              abuse, please, immediately contact us to report the abuse.
            </p>
          </div>

          <div className="textPage-item">
            <Link
              to="/report-abuse"
              className="button button__type8 button__bottom button-honnor"
              type="button"
            >
              report abuse
            </Link>
          </div>
        </Holder>
      </TextPage>
    </LocalesPage>
  );
};
