import React from "react";
import Text from "../../../../base_components/Text";

export const InvestTitle = <Text>Focus on things that matter and let<br className="desktop-only"/> Nerds help you get prepared:</Text>;

export const InvestItems = [
    {
      title: "Academia",
      icon: {
        name: "invest1-icon",
        width: 23,
        height: 27,
      },
      list: [
        "Basic statistical analysis",
        "On-demand research",
        "Preparation of questionnaires"
      ],
      linkMessage: "Learn more →",
      linkUrl: "/academia",
    },
    {
      title: "Students",
      icon: {
        name: "invest2-icon",
        width: 23,
        height: 27,
      },
      list: [
        "Topics and problems explanation",
        "Data collection and source suggestion",
        "Research methodology advice"
      ],
      linkMessage: "Learn more →",
      linkUrl: "/students",
    },
    {
      title: "Businesses",
      icon: {
        name: "invest3-icon",
        width: 23,
        height: 27,
      },
      list: [
        "Industry trends identification",
        "Market research and scoping",
        "Help with business presentations"
      ],
      linkMessage: "Learn more →",
      linkUrl: "/businesses",
    }
  ];
