import React from "react";

export const SliderCombinedItems = {
  Academia: [
    {
      tag: "Get from $25",
      title: <>Collect data for a specific<br /> research</>,
      img: "academia-img1"
    },
    {
      tag: "Get from $15",
      title: <>Create questions for online<br /> surveys</>,
      img: "academia-img2"
    },
    {
      tag: "Get from $30",
      title: <>Analyze the results of questionnaires</>,
      img: "academia-img3"
    },
  ],
  Students: [
    {
      tag: "Get from $25",
      title: <>Detailed explanation of a topic</>,
      img: "students-img1"
    },
    {
      tag: "Get from $20",
      title: <>Proofread application<br /> letters</>,
      img: "students-img2"
    },
    {
      tag: "Get from $20",
      title: <>Change presentation<br /> formatting</>,
      img: "students-img3"
    },
  ],
  Businesses: [
    {
      tag: "Get from $25",
      title: <>Structure a corporate<br /> presentation</>,
      img: "business-img1"
    },
    {
      tag: "Get from $50",
      title: <>Make a market analysis report</>,
      img: "business-img2"
    },
    {
      tag: "Get from $40",
      title: <>Conduct a brand reputation<br /> audit</>,
      img: "business-img3"
    },
  ],
};
