import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import { REACT_APP_CONTACT_EMAIL } from './../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage au>
      <Holder className="holder_1">
        <h1 className="h1">Refund Policy</h1>
        <p className="p">
          The Users of the website can avail the services of the Freelancer, but
          these are not limited to conducting research, helping with data entry
          and data collection, organizing surveys, performing analysis,
          proofreading and editing of content, giving consultations, providing
          tutoring, etc.{' '}
        </p>
        <div className="textPage-item">
          <h3 className="h3">
            What do I do if I’m dissatisfied with the delivered service by the
            Freelancer?
          </h3>
          <p className="p">
            Feel free to request a refund of your payment, but requests have to be sent within 60 calendar days after the completion of your request. Please send us an email containing your order number and complaints/feedback at{' '}
            <a
              className="a"
              href={`mailto:${REACT_APP_CONTACT_EMAIL}`}
              rel="noopener noreferrer"
            >
              {REACT_APP_CONTACT_EMAIL}
            </a>
            .
          </p>
        </div>
        <div className="textPage-item">
          <h3 className="h3">What’s next?</h3>
          <p className="p">
            Your request for a refund will be under investigation by
            thenerdify.com for three to five business days. Please note that
            Nerdify’s virtual money (stored on your Nerdify account) is
            non-refundable. We can also allocate your task to another Freelancer
            within 24 hours and it’s 100% free! We can also refund your payment
            to your account/credit card.
          </p>
        </div>
        <div className="textPage-item">
          <h3 className="h3">Refund of your subscription plan</h3>
          <p className="p">
            You can request a refund for the subscription fee charged on your
            card up to 30 days from the date of the latest payment. The refund
            can be issued only if you did not use your bonuses and perks
            included in the subscription plan since the last payment. In this
            case, your refund request will be processed within 24 hours and
            you'll get your money back to the card within three to five business
            days. Please be aware that the bonuses and perks included in your
            subscription plan are non-refundable and will be withdrawn on the
            date of the refund issued.
          </p>
        </div>
        <div className="textPage-item" />
        &nbsp;
        <div className="textPage-item" />
      </Holder>
    </TextPage>
  </LocalesPage>
);
