import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
//import {useSelector} from "react-redux";
import {useMobile} from "../../../../hooks";
import LocalesPage from './../LocalesPage';
import regexps from '../../../../utils/regexps';

import { HeaderCA, HeaderIconCA } from './../../../../themes/nerdify/components/Header';
import {HnavExt} from "../../../../themes/nerdify/components/Hnav";
import {HnavMobExt} from "../../../../themes/nerdify/components/HnavMob";
import {HeaderNavListExt, HeaderNavSubList} from "../../blocks/HeaderNav";

import { HeroNew, HeroImg  } from '../../../../themes/nerdify/components/Hero';
import Content from '../../../../themes/nerdify/components/Content';
import { Section2 } from '../../../../themes/nerdify/components/Section';
import Holder from '../../../../themes/nerdify/components/Holder';
import Attach from '../../../../themes/nerdify/components/Attach';
import InputError from '../../../../themes/nerdify/components/Form/InputError';
import Icon from '../../../../themes/nerdify/components/Icons';
import InputSelect from './../../../../themes/nerdify/components/Form/InputSelect';
import { Hiw3, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw';
import { ModalLoginForm, ModalApplicationForm } from './../../../../themes/nerdify/components/Modal';
import { Intro2, Intro4 } from '../../../../themes/nerdify/components/Intro';
import { Form1 } from '../../../../themes/nerdify/components/Form';

import { LEVELS, YEARS, COUNTRIES } from '../../../../config';
//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import { Footer2 } from '../../blocks/Footer'

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile,
  });
};


class BecomeANerd extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      name: '',
      phone: '',
      email: '',
      level: '',
      country: '',
      college: '',
      major: '',
      year: '',
      fields: '',
      about: '',
      url: '',
      agree: false,
      errors: {},
      isHasClick: false,
      isDisabled: false,
      isSuccess: false,
      isLogin: false,
      isClear: false,
    };

    this.state = this.initialState;

    this.onMakeLogin = this.onMakeLogin.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.validate = this.validate.bind(this);
  }

  onMakeLogin() {
    this.setState({ isLogin: !this.state.isLogin });
  }

  onClose() {
    this.setState({
      isClear: true,
    }, () => {
      this.setState({...this.initialState});
    });
  }

  onChangeCheckbox(e) {
    const { isHasClick } = this.state;
    this.setState({
      agree: e.target.checked
    }, () => {
      if (isHasClick) {
        this.validate();
      }
    })
  }

  onChangeInput(e) {
    const { name, value } = e.target;
    const { isHasClick } = this.state;

    this.setState({
      [name]: value
    }, () => {
      if (isHasClick) {
        this.validate();
      }
    });
  }

  validate() {
    const {
      name,
      phone,
      email,
      level,
      country,
      college,
      major,
      year,
      fields,
      about,
      url,
      agree,
    } = this.state;

    const errors = {
      name: !name.trim().length ? 'Required field' : null,
      phone: !regexps.phone(phone.trim()) ? 'Required field' : null,
      email: !regexps.email(email.trim()) ? 'Required field' : null,
      level: !level.trim().length ? 'Required field' : null,
      country: !country.trim().length ? 'Required field' : null,
      college: !college.trim().length ? 'Required field' : null,
      major: !major.trim().length ? 'Required field' : null,
      year: !year.trim().length ? 'Required field' : null,
      fields: !fields.trim().length ? 'Required field' : null,
      about: !about.trim().length ? 'Required field' : null,
      url: !url.trim().length ? 'Required field' : null,
      agree: !agree ? 'Required field' : null,
    };

    const isError = Object.keys(errors).some(item => errors[item]);

    this.setState({
      errors,
      isDisabled: isError,
    });

    return isError;
  }

  onSubmit() {
    this.setState({ isHasClick: true });

    const isError = this.validate();
    if (!isError) {
      this.setState({ isSuccess: true });
    }
  }

  render() {
    const {
      name,
      phone,
      email,
      level,
      country,
      college,
      major,
      year,
      fields,
      about,
      url,
      agree,
      errors,
      isDisabled,
      isSuccess,
      isLogin,
      isClear,
    } = this.state;
    //const { pageCfg } = this.props;

    return (
      <WithHook>
        {({ isMobile }) => {
          //const locale = useSelector(state => state.locales.locale)
    return (
      <LocalesPage {...this.props}>
       <div className="become-a-nerd">
{/*
        <Header1>
          <Holder>
            <NavLink className='logo-link' to='/'>
              {pageCfg.defaultLocale === 'au' ?
                <HeaderIcon iconName='logo-au' />
                :
                <HeaderIcon iconName='logo-us' />
              }
            </NavLink>
            <div className="header-wrap">
              <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
              <HnavMob2 />
              <InputSelectLocales
                className='input-select__short'
                name='country'
                isError={false}
              />
            </div>
          </Holder>
        </Header1>
*/}
         <HeaderCA type={3}>
           <Holder>
             <NavLink className="logo-link" to="/">
               <HeaderIconCA />
             </NavLink>
             <div className="header-wrap">
               {isMobile ? <HnavMobExt list={HeaderNavListExt} sublist={HeaderNavSubList} /> : <HnavExt list={HeaderNavListExt} /> }
             </div>
           </Holder>
         </HeaderCA>

         <HeroNew subTheme={2}>
           <div className="hero-main">
             {!isMobile && <HeroImg className="hero__img desktop-only" img={"new2.png"} img2x={"new2@2x.png"} />}
             <div className="holder">
               <h1 className="h0 hero__title">Are you an expert<br /> in your field?</h1>
               <hr className="hr hero__hr" />
               <h5 className="h5 hero__subtitle">Already a freelance Nerd?</h5>
               <button className="button__type18" onClick={this.onMakeLogin}>Log in</button>
               {isMobile && <HeroImg className="hero__img mobile-only" img={"new2-mob.png"} img2x={"new2-mob@2x.png"} />}
             </div>
           </div>
         </HeroNew>


{/*
        <Hero2 key='becomeANerd-hero'>
          <h1 className="h0 hero__title">Are you an expert<br /> in your field?</h1>
          <hr className="hr hero__hr desktop-only" />
          <h5 className="h5 hero__subtitle">Already a freelance Nerd?</h5>
          <button className="button__type10" onClick={this.onMakeLogin}>Log in</button>
        </Hero2>
*/}

        <Intro2>
          <Holder className="holder_1">
            <h4 className="h4">We are always happy to greet skilled individuals into our Nerdify freelance family.</h4>
            <h4 className="h4">Our platform is an advanced software connecting the brightest individuals with those who seek help with urgent tasks.</h4>
            <h6 className="h6">
              Are you a graduate or nearly done with your degree?
              <br />
              Don't miss this chance!
            </h6>
          </Holder>
        </Intro2>

         <div className="hr_6" />

         <Intro4 indent={"pt40 pb40m pb60d"}>
           <Holder className={"holder_3"}>
             <div className="intro__title">Disclaimer</div>
             <div className="intro__msg">Please be aware by sending the application to become a Nerd you agree to follow our Honor Code that does not allow providing any type of service that violates academic, corporate or any other policies. Please read our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink> and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks to learn more.</div>
           </Holder>
         </Intro4>

         <div className="hr_6" />

        <Content key='becomeANerd-content'>

          <Hiw3>
            <h3 className='h3'>How to join us?</h3>

            <HiwList>
              <HiwItem>
                <HiwImg img={'hiw7'} />
                <HiwText>
                  <p className="p">Fill out the Application Form</p>
                </HiwText>
              </HiwItem>
              <HiwItem>
                <HiwImg img={'hiw8'} />
                <HiwText>
                  <p className="p">Pass all Examination Tests</p>
                </HiwText>
              </HiwItem>
              <HiwItem>
                <HiwImg img={'hiw9'} />
                <HiwText>
                  <p className="p">Get an Approval Notice</p>
                </HiwText>
              </HiwItem>
              <HiwItem>
                <HiwImg img={'hiw10'} />
                <HiwText>
                  <p className="p">Earn by Helping Others!</p>
                </HiwText>
              </HiwItem>
            </HiwList>

            <p className="p p__tiny hiw__text">
              Please note that it might take a few weeks to contact you back.<br className="desktop-only"/> We are accepting applications but the demand for freelancers in sully met at the moment.
            </p>
          </Hiw3>

          <Holder className="holder_1 mobile-only">
            <hr className="hr" />
          </Holder>

          <Section2 items={6}>
            <Holder className="holder_1">
              <h2 className="h2">
                Fill In the Application Form
              </h2>
            </Holder>
            <Holder>
              <Form1>
                <fieldset className="fieldset fieldset__mobile-padding">
                  <label className="label">Your name and surname</label>
                  <input
                    className={classnames("input", { "input__error": errors.name })}
                    type="text"
                    name="name"
                    value={name}
                    placeholder=""
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                    onChange={this.onChangeInput}
                  />
                  {!!errors.name &&
                  <InputError>{errors.name}</InputError>
                  }
                </fieldset>
                <fieldset className="fieldset fieldset__mobile-padding">
                  <div className="fieldset-item">
                    <label className="label">Your phone number</label>
                    <div className="input-wrapper">
                      <input
                        className={classnames("input", { "input__error": errors.phone })}
                        type="tel"
                        name="phone"
                        value={phone}
                        placeholder=""
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />
                      {!!errors.phone &&
                      <InputError>{errors.phone}</InputError>
                      }
                    </div>
                  </div>
                  <div className="fieldset-item">
                    <label className="label">Your email</label>
                    <div className="input-wrapper">
                      <input
                        className={classnames("input", { "input__error": errors.email })}
                        type="email"
                        name="email"
                        value={email}
                        placeholder=""
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        onChange={this.onChangeInput}
                      />
                      {!!errors.email &&
                      <InputError>{errors.email}</InputError>
                      }
                    </div>
                  </div>
                </fieldset>
                <fieldset className="fieldset fieldset__mobile-padding">
                  <Attach id="become-a-nerd-file-1" clear={isClear}>Upload your photo</Attach>
                </fieldset>
                <div className="form-item fieldset__mobile-padding">
                  <fieldset className="fieldset">
                    <label className="label">Your level of education</label>
                    <div className="input-wrapper">
                      {/*<input
                      className={classnames("input", { "input__error": errors.level })}
                      type="text"
                      name="level"
                      value={level}
                      placeholder=""
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="off"
                      onChange={this.onChangeInput}
                    />*/}
                      <InputSelect
                        name='level'
                        items={LEVELS}
                        value={level}
                        onChange={this.onChangeInput}
                        isError={errors.level}
                      />
                      {!!errors.level &&
                      <InputError>{errors.level}</InputError>
                      }
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <div className="fieldset-item">
                      <label className="label">Your country of residence</label>
                      <div className="input-wrapper">
                        {/*<input
                        className={classnames("input", { "input__error": errors.country })}
                        type="text"
                        name="country"
                        value={country}
                        placeholder=""
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />*/}
                        <InputSelect
                          name='country'
                          items={COUNTRIES}
                          value={country}
                          onChange={this.onChangeInput}
                          isError={errors.country}
                        />
                        {!!errors.country &&
                        <InputError>{errors.country}</InputError>
                        }
                      </div>
                    </div>
                    <div className="fieldset-item">
                      <label className="label">Your university/college name</label>
                      <div className="input-wrapper">
                        <input
                          className={classnames("input", { "input__error": errors.college })}
                          type="text"
                          name="college"
                          value={college}
                          placeholder=""
                          autoCapitalize="off"
                          autoCorrect="off"
                          autoComplete="off"
                          onChange={this.onChangeInput}
                        />
                        {!!errors.college &&
                        <InputError>{errors.college}</InputError>
                        }
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <div className="fieldset-item">
                      <label className="label">Your major(s)</label>
                      <div className="input-wrapper">
                        <input
                          className={classnames("input", { "input__error": errors.major })}
                          type="text"
                          name="major"
                          value={major}
                          placeholder=""
                          autoCapitalize="off"
                          autoCorrect="off"
                          autoComplete="off"
                          onChange={this.onChangeInput}
                        />
                        {!!errors.major &&
                        <InputError>{errors.major}</InputError>
                        }
                      </div>
                    </div>
                    <div className="fieldset-item">
                      <label className="label">Your graduation year</label>
                      <div className="input-wrapper">
                        {/*<input
                        className={classnames("input", { "input__error": errors.year })}
                        type="text"
                        name="year"
                        value={year}
                        placeholder="2019"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />*/}
                        <InputSelect
                          name='year'
                          items={YEARS}
                          value={year}
                          onChange={this.onChangeInput}
                          isError={errors.year}
                        />
                        {!!errors.year &&
                        <InputError>{errors.year}</InputError>
                        }
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <Attach id="become-a-nerd-file-2" clear={isClear}>Attach a scan of your diploma</Attach>
                    <p className="p p__tiny">*we ask you to provide a school verification in order to confirm that you are currently or have once been enrolled in a university.</p>
                  </fieldset>
                </div>
                <div className="form-item fieldset__mobile-padding">
                  <fieldset className="fieldset">
                    <label className="label">What are the fields you specialize in?</label>
                    <div className="input-wrapper">
                    <textarea
                      className={classnames("textarea", { "textarea__error": errors.fields })}
                      name="fields"
                      value={fields}
                      placeholder="Enter fields separated by comma"
                      onChange={this.onChangeInput}
                    />
                      {!!errors.fields &&
                      <InputError>{errors.fields}</InputError>
                      }
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <label className="label">Tell us more about yourself</label>
                    <div className="input-wrapper">
                    <textarea
                      className={classnames("textarea", { "textarea__error": errors.about })}
                      name="about"
                      value={about}
                      placeholder="A few words, just to know you better"
                      onChange={this.onChangeInput}
                    />
                      {!!errors.about &&
                      <InputError>{errors.about}</InputError>
                      }
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <Attach id="become-a-nerd-file-3" clear={isClear}>Attach your CV</Attach>
                  </fieldset>
                  <fieldset className="fieldset">
                    <label className="p">Verify your identity by sharing <strong>either Facebook or LinkedIn</strong> profile URLs:</label>
                    <div className="input-wrapper">
                      <input
                        className={classnames("input", { "input__error": errors.url })}
                        type="text"
                        name="url"
                        value={url}
                        placeholder="Paste URL"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />
                      {!!errors.url &&
                      <InputError>{errors.url}</InputError>
                      }
                    </div>
                  </fieldset>
                </div>
                <fieldset className="fieldset">
                  <div className="agree">
                    <p className="p p__tiny">Nerdify was created as a cutting-edge solution for students, academia, and businesses seeking for the top-notch personalized assistance. We highly appreciate the trust of our clients and constantly strive to bring only positive experience. Quality and punctuality are meant to be top priorities for every Nerd that works with us. We are strongly against exposing personal information, promoting gambling, cheating or drug use. By applying to join our team you agree to work due to company's guidelines.</p>
                    <fieldset className="fieldset">
                      <input
                        id="agree"
                        className={classnames("checkbox", { "checkbox__error": errors.agree })}
                        type="checkbox"
                        name="agree"
                        checked={agree}
                        onChange={this.onChangeCheckbox}
                      />
                      <label className="label" htmlFor="agree">
                        I agree to
                        {' '}
                        <NavLink className="a__underline" to="/terms-and-conditions">Terms of Use</NavLink>,
                        {' '}
                        <NavLink className="a__underline" to="/privacy-policy">Privacy Policy</NavLink>,
                        {' '}
                        <NavLink className="a__underline" to="/cookies">Cookie Policy</NavLink>,
                        {' '}
                        <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>
                        {' '}
                        and processing of my personal data.
                      </label>
                      <Icon iconName="check" className="icon-checkbox" />
                    </fieldset>
                  </div>
                </fieldset>
                <div className="fieldset">
                  <button type="button" className="button button__type6 form__btn" onClick={this.onSubmit} disabled={isDisabled}>Submit Application Form</button>
                </div>
              </Form1>
            </Holder>
          </Section2>

          {isSuccess &&
          <ModalApplicationForm isOpen={isSuccess} onClose={this.onClose} />
          }

          {isLogin &&
          <ModalLoginForm isOpen={isLogin} onClose={this.onMakeLogin} logo={'dark-glasses2'} />
          }

        </Content>
        <Footer2 key='becomeANerd-footer' />
      </div>
      </LocalesPage>
    )
  }
}
      </WithHook>
    );
  }
}

export default BecomeANerd;
