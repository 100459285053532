import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import classnames from 'classnames';

import { Hanv1Theme, Hanv2Theme } from '../../../../themes/nerdify/components/Hnav';
import Dropdown from './../../../../themes/nerdify/components/Dropdown';

import {  } from './../../../../redux/actions/';

class HanvContent extends Component {
  render () {
    return (
      <div className='hnav__list'>
        <div className='hnav__item'>
          <NavLink to='/how-it-works' className={'hnav__link'}>How it works</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/top-nerds' className={'hnav__link'}>Top Nerds</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/become' className={'hnav__link'}>For Nerds</NavLink>
        </div>
        <div className='hnav__item'>
          <Dropdown
            items={[
              {
                name: 'For businesses',
                link: '/businesses',
              },
              {
                name: 'For students',
                link: '/students',
              },
              {
                name: 'For colleges',
                link: '/colleges',
              }
            ]}
          />
        </div>
      </div>
    )
  }
}

export const Hnav1 = () => {
  return (
    <Hanv1Theme>
      <HanvContent />
    </Hanv1Theme>
  )
};

export const Hnav2 = () => {
  return (
    <Hanv2Theme>
      <HanvContent />
    </Hanv2Theme>
  )
};
