import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';
import {NavLink} from "react-router-dom";

/*
import {
  REACT_APP_CONTACT_EMAIL,
  //REACT_APP_ADDRESS_UK,
} from './../../../../config';
*/

export default (props) => (
  <LocalesPage {...props}>
    <TextPage au>
      <Holder className="holder_1">
        <h1 className="h1">Terms of Use</h1>

        <p className="p">
          We refer to our Users as “you.” Every user is required to read the terms and conditions presented below. If you feel that this document contradicts your personal beliefs, attitudes or views, then you are prohibited from using our service. All Users and visitors are requested to interact with Nerdify Platform in strict compliance with the conditions below and your respective laws. You can refer to thenerdify.com as “our,” “us,” and “we.”
        </p>

        <div className="textPage-item">
          <h3 className="h3">About us</h3>
          <p className="p">
            Thenerdify.com is an online platform that caters to the needs of our
            Users. We refer them to our Freelancers for their needs. They serve
            as their personal assistants. We consider our Freelancers as
            contractual employees, hence, the company does not represent them in
            any way. Like any other freelance platform, we do not approve of
            anyone to maintain illegal activities on our website. Bear in mind
            that we do not exercise control and responsibility for the service
            that Freelancers will provide.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Thenerdify.com's Contact Information Usage and Platform Messaging Program</h3>
          <ul className="ul">
            <li>Thenerdify.com uses text messaging (SMS) as a prime way of delivering our services. By requesting a text-back on our website you agree to receive text messages (SMS) as a part of our platform messaging program. Participating in the SMS program is optional, but please be aware that we are not able to deliver our services without using text messages (SMS).</li>
            <li>Your phone number will only be used as a tool for communication regarding your requests. You will receive the following types of messages: text-back messages, information about our services, request-related quotes, request-related status updates, answers to your questions and other types of messages that help us provide you with relevant information and services.</li>
            <li>Your phone number is safely stored on our hosting. We use Amazon Web Services, which is one of the most secure hosting providers in the world.</li>
            <li>Your phone number will never be passed on to any third parties and can be reached only by the employees of Nerdify</li>
            <li>You can cancel the SMS service at any time. If you wish to stop getting texts from us please text “STOP”. Upon receiving this text message, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us.</li>
            <li>If you want to join again, just visit our website and send a request for text back, and we will start sending SMS messages to you again.</li>
            <li>If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance.</li>
            <li>Thenerdify.com does not charge you any extra fee for chatting with us via SMS. However, operator message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.</li>
            <li>Carriers are not liable for delayed or undelivered messages.</li>
            <li>If you have any questions regarding privacy, please read our <NavLink className="a" to="/privacy-policy">Privacy Policy</NavLink>.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Thenerdify.com's Content and Copyright</h3>
          <p className="p">
            The provided content, images, files, etc. other than those of third
            parties, are copyrighted by thenerdify.com. You may not copy or
            transfer our content, as it violates copyright law.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Thenerdify.com's Policy on Refund</h3>
          <p className="p">
            You may refund your payment for the Freelancer’s service. You are
            eligible for a refund 60 days after your request was completed.
            Then, your request will be under review for three to five business
            days before we finalize it. Your money will be refunded to your
            Nerdify balance or your bank account. Or we can also find another
            Freelancer for you to complete your request which might take 24
            hours.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Our Users’ Safety and Security</h3>
          <p className="p">As a User, you are not allowed to:</p>
          <ul className="ul">
            <li>Gather other Users’ personal data;</li>
            <li>
              Engage in illegal activities that may harm the website and its
              users;
            </li>
            <li>Spread and embed viruses and malware;</li>
            <li>Solicit and beg for other Users’ personal data;</li>
            <li>Harass, threaten, or bully any individual on the platform;</li>
            <li>
              Use the platform to participate in unlawful and destructive acts;
            </li>
            <li>Disable and malfunction the website’s services;</li>
            <li>
              Persuade and force any User to take part in illegal activities.
            </li>
          </ul>
          <p className="p">
            We adhere to high quality and standards of work. Therefore, we
            expect the same from our Users by refusing to process anyone’s
            requests with the following connections:
          </p>
          <ul className="ul">
            <li>
              Helping or attempting to help another to commit an act of
              corporate/academic dishonesty:
            </li>
            <li>
              Assisting students to cheat, or obtain grades or degrees they have
              not earned;
            </li>
            <li>
              Plagiarizing the work of another person without proper
              attribution;
            </li>
            <li>Impersonating someone’s identity for any purpose.</li>
            <li>
              Trafficking drugs and arms/trade and involvement in alcohol;
            </li>
            <li>Promoting anarchic and destructive activities;</li>
            <li>Stealing (virtual goods);</li>
            <li>
              Glorifying violence, colorism, racism, sexism, misandry, etc. and
              writing hate speech;
            </li>
            <li>
              Establishing connections with anyone promoting/possessing explicit and vulgar materials/services;
            </li>
            <li>
              Connecting with anyone related to the purchase of ammunition,
              weapons, and projectiles;
            </li>
            <li>
              Partaking in an act that encourages other users to violate the
              company’s policies;
            </li>
            <li>
              Engaging in matrix programs and “quick and easy money” schemes;
            </li>
            <li>Selling goods before the merchant has control of them;</li>
            <li>Collecting payments for the vendors/paying the vendors;</li>
            <li>
              Affiliating any individual with ordering money and travel cheques;
            </li>
            <li>Taking part in debt settlement and credit repair services;</li>
            <li>Undertaking fraudulent requests/actions.</li>
          </ul>
          <p className="p">
            Users of this platform are also expected not to write any message
            containing the following:
          </p>
          <ul className="ul">
            <li>Curse words;</li>
            <li>Offensive remarks towards a group;</li>
            <li>
              Promotion of violence, racism, sexism, colorism, misandry, and
              hate speech;
            </li>
            <li>Representation of a minor on the platform;</li>
            <li>Pornography and graphic violence;</li>
            <li>A link to contests and other commercial activities;</li>
            <li>Spam and junk mail;</li>
            <li>Viruses and malware.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Deciding to Subscribe to our Services</h3>
          <p className="p">
            Thenerdify.com offers a monthly subscription plan with premium
            features that can include VIP support, free extra services and
            bonuses added to your account that you can use to order the services
            within the paid billing period from the date of the latest payment.
            The subscription fee for the monthly plan is due on the same date
            for any month, or the closest date in that month, to the day you
            enrolled into the subscription and made your first monthly payment.
            The bonuses and additional perks included in your subscription plan
            are valid within the paid billing period from the date of the latest
            payment. Your subscription plan will be automatically renewed and
            Thenerdify.com will deduct monthly fees against your credit card
            until you cancel your membership. You may cancel your subscription
            at any time by contacting our support. In this case your bonuses
            remain unaffected for the remaining billing period but you will not
            be charged on the date of the recurring payment. If, for some
            reason, we are unable to process your credit card, you will receive
            an email notification. In this case we will try to process your
            payment for 2 weeks and then cancel your subscription until you
            update your card details and the payment can be processed.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Thenerdify.com's Limitations of Liability</h3>
          <p className="p">The company and its third-party partners are not liable for any damages the following may have or may have not directly or indirectly caused:</p>
          <ul className="ul">
            <li>Services provided by Freelancers;</li>
            <li>Our content;</li>
            <li>Your online behavior;</li>
            <li>Company investigations/ authorized investigations;</li>
            <li>
              Any related action taken by copyright and intellectual property
              holders;
            </li>
            <li>Technical errors on our website;</li>
            <li>
              Damages to the User’s device, but this is not limited to online
              security breaches, bugs, glitches, and the like.
            </li>
          </ul>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Disclaimer</h3>
          <ul className="ul">
            <li>
              We are in charge of facilitating the interaction between you and
              the Freelancer. Agreements you set with the Freelancer are not
              within our control.
            </li>
            <li>
              Freelancers are screened and checked by our team. The company does
              not verify the authenticity of our Freelancers' credentials,
              qualifications, and background. Meetups either physically or
              virtual are also not in our control. It is solely at your own
              risk.
            </li>
          </ul>
          <p className="p">
            You agree to the following provisions by accessing thenerdify.com:
          </p>
          <ul className="ul">
            <li>
              You can ask for legal assistance if it involves the Freelancer and
              third parties;
            </li>
            <li>
              You cannot seek legal help from us, as we do not control or take
              responsibility for the authenticity of the information supplied by
              the Freelancer, or even between the both of you.
            </li>
          </ul>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Links</h3>
          <p className="p">
            Using and clicking on external links is solely up to you, as
            thenerdify.com has not analyzed the content in each external link.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Terms of Modifications</h3>
          <p className="p">
            This document can be modified at any given time without notifying
            the Users. You are bound by the current version of our Terms of Use.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Freelancer’s Pledge</h3>
          <p className="p">
            As a Freelancer at Nerdify Platform, I am expected to uphold the company’s proper decorum and high standards. Therefore, I pledge to:
          </p>
          <ul className="ul">
            <li>
              Supply accurate information about my expertise and education to
              aid the User;
            </li>
            <li>
              Prohibit the User to participate in academic dishonesty and break
              US university regulations and state laws;
            </li>
            <li>
              Refuse to partake in any activity that allows the company to pay
              sums of money and royalties to third parties;
            </li>
            <li>
              Understand and acknowledge that my cooperation with the company
              will be terminated if I violate any terms in this document.
            </li>
          </ul>
        </div>
      </Holder>
    </TextPage>
  </LocalesPage>
);
