import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import NotFound, { NotFoundIcon } from './../../../../themes/nerdify/components/NotFound';
import { NavLink } from 'react-router-dom'
import { REACT_APP_CONTACT_EMAIL } from './../../../../config';
import { HeaderCA, HeaderIconCA } from './../../../../themes/nerdify/components/Header';
import { Footer2 } from '../../blocks/Footer';


export default () => (
  <>
    <div>
      <HeaderCA type={4}>
        <Holder>
          <NavLink className="logo-link" to="/">
            <HeaderIconCA />
          </NavLink>
        </Holder>
      </HeaderCA>

      <NotFound>
        <Holder className={'holder_1 not-found2'}>
          <h1 className="title">Something went wrong</h1>
          <div className="h6">It seems the page you are looking for does not exist, <br className="desktop-only"/>
            please contact us via <a className="a" href={`mailto:${REACT_APP_CONTACT_EMAIL}`}>{REACT_APP_CONTACT_EMAIL}</a>.</div>
          <NotFoundIcon img={'404.png'} img2x={'404@2x.png'} />
          <NavLink className="button button__type19" to='/'>Back to homepage</NavLink>
        </Holder>
      </NotFound>
    </div>
    <Footer2/>
  </>
)
