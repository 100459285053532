import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import './index.css';

import { REACT_APP_CONTACT_EMAIL } from '../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage au>
      <Holder className="holder_1">
        <h1 className="h1">Cookie Policy</h1>

        <div className="textPage-item">
          <h3 className="h3">Cookie Policy: An Overview</h3>
          <p className="p">
            The purpose of this document is to officially inform anyone who is visiting thenerdify.com for any purpose that we may use cookies and other identification technologies for our services. In addition to using these on our website, we may also use these technologies in our apps, messaging utilities, tools, and other services. The purpose of using includes but is not limited to authenticating users, remembering user preferences and settings, determining the popularity of content, delivering personalized and more relevant advertisements, measuring the effectiveness of advertising campaigns, analyzing website traffic and trends, and generally understanding the online behaviors and interests of people who interact with our Services. The objective of this policy is to provide our users and visitors with the information they need about how their information is used.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Definition of Terms: Cookies</h3>
          <p className="p">
            Cookies are small files that are downloaded to your phones, laptop,
            or any device you use when you visit thenerdify.com. By downloading
            these files, we are able to track data about your use of our
            website, apps, and other utilities. By using cookies, we are able to
            remember visitors when they return to our website multiple times.
            Let’s learn about the cookies we use:{' '}
          </p>
          <ol className="textPage-ol">
            <li>
              <strong>First-Party Cookies:</strong> the cookies we set are first-party cookies. We use them for information retention when you exit our website. These cookies retain your information, such as your log-in details.
            </li>
            <li>
              <strong>Third-Party Cookies:</strong> they are downloaded and
              installed by other entities. thenerdify.com does not control
              these.
            </li>
            <li>
              <strong>Session Cookies:</strong> they contain current browsing
              sessions on our website. These cookies are deleted once you close
              your browser.
            </li>
            <li>
              <strong>Persistent Cookies:</strong> these cookies remain
              installed even after you exit our website. For instance,
              thenerdify.com can remember your preferences.
            </li>
          </ol>
        </div>

        <div className="textPage-item">
          <h3 className="h3">
            Definition of Terms: Identification Technologies
          </h3>
          <ol className="textPage-ol">
            <li>
              <strong>Pixel Tags/Pixels/Beacons:</strong> these are small files
              that are designed to work with cookies. These are used to
              recognize returning users. They can identify the User’s device
              type, IP address, and many more. They also collect anonymous data
              on their on-site behavior.
            </li>
            <li>
              <strong>Local Storage: </strong> where information from browsers,
              third parties, and websites is stored.
            </li>
            <li>
              <strong>Software Development Kits (SDKs):</strong> they operate in
              a mobile application and function like cookies and pixels. The
              partners of developers can acquire various information from their
              users through SDKs.
            </li>
          </ol>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Consenting to Use of Cookies</h3>
          <p className="p">
            As a User of thenerdify.com, you have the right to reject or accept
            cookies. However, our services cannot function properly without the
            use of cookies. In the event that you do not want to provide this
            consent, you may adjust your settings to block cookies and other
            technologies by clicking “Help” or “Preferences” on your browser.
            However, some third parties may allow you to reject cookies through
            a link.
          </p>
          <p className="p">
            Please note that rejecting or removing cookies from your browser may
            not affect third-party flash cookies that we may possibly employ for
            our services. Refer to the links below for more information:
          </p>
          <ul className="ul">
            <li>
              <a
                className="a"
                href="http://allaboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.allaboutcookies.org
              </a>
            </li>
            <li>
              <a
                className="a"
                href="http://youronlinechoices.eu"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.youronlinechoices.eu
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://adssettings.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.adssettings.google.com
              </a>
            </li>
          </ul>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Cookies: Types and Purposes</h3>
          <p className="p">
            We would like to present you a table of the cookies and our reasons
            for their usage:
          </p>
          <table className="table p">
            <thead>
            <tr>
              <th width="17%">Type of Cookie</th>
              <th>Purpose</th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td data-label="Type of Cookie">Essentials</td>
              <td data-label="Purpose">Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</td>
            </tr>
            <tr>
              <td data-label="Type of Cookie">Marketing</td>
              <td data-label="Purpose">Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed.</td>
            </tr>
            <tr>
              <td data-label="Type of Cookie">Performance</td>
              <td data-label="Purpose">Help research, understand and improve products and Services. For example, when you access the Nerdify website or other related websites and apps from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</td>
            </tr>
            <tr>
              <td data-label="Type of Cookie">Functional</td>
              <td data-label="Purpose">These cookies and similar technologies remember choices you make such as language or search parameters. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Feedback</h3>
          <p className="p">
            The aforementioned paragraphs detail our use of these technologies.
            Specifically, we detail how and why we use them. It is our hope that
            this document will provide sufficient information. However, we
            invite anyone with further questions to contact us at{' '}
            <a
              className="a"
              href={`mailto:${REACT_APP_CONTACT_EMAIL}`}
              rel="noopener noreferrer"
            >
              {REACT_APP_CONTACT_EMAIL}
            </a>
            .
          </p>
        </div>
      </Holder>
    </TextPage>
  </LocalesPage>
);
